<script lang="ts">
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import { inertia } from '@inertiajs/svelte';

    interface TabItem {
        label: string;
        url: string;
    }

    export let items: TabItem[] = [
        {
            label: 'posts',
            url: '/markets/my/offers',
        },
        {
            label: 'users',
            url: '/markets/my/users',
        },
        {
            label: 'History',
            url: '/markets/my/history',
        },
    ];

    export let activeTabValue: string = window.location.pathname;
</script>

<svelte:component this="{MarketplaceNav}" />
<div class="col-12 pr-0 pt-4">
    <div class="px-3 pb-2">
        <h5 class="text-truncate mb-0 font-bold">My Market</h5>
        <div class="mt-md-3 inline-border-tabs mb-1 mt-0">
            <nav class="nav nav-pills nav-justified">
                {#each items as tab}
                    <a
                        use:inertia
                        class="nav-item nav-link"
                        href="{tab.url}"
                        class:active="{activeTabValue === tab.url}"
                    >
                        <div class="d-flex align-items-center justify-content-center">
                            {tab.label}
                        </div>
                    </a>
                {/each}
            </nav>
        </div>
    </div>
</div>
